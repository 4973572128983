<template>
  <div class="container">
    <h2 class="text-center text-blue underline">Our Services</h2>
    <button v-if="getProfile.roles.includes('admin')" class="btn btn-add">
      <router-link to="/services/new">Add Service</router-link>
    </button>
    <br /><br />
    <div class="row">
      <div
        v-for="(service, index) in getServices"
        :key="index"
        class="col-md-4 col-sm-6 d-flex align-items-stretch"
      >
        <service-card :service="service" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ServiceCard from "./ServiceCard.vue";

export default {
  name: "AllServices",
  components: { ServiceCard },
  computed: { ...mapGetters(["getProfile", "getServices"]) },
};
</script>

<style scoped>
.btn-add {
  background-color: #023b95;
  border-radius: 8px;
  padding: 10px;
  color: white;
}

.btn-add a {
  color: white;
  text-decoration: none;
}
</style>
