<template>
  <div class="card mb-4">
    <div class="card-body">
      <h3 class="card-title text-blue" @click="getService">
        <b>{{ service.name }}</b>
      </h3>
      <p class="card-text text-secondary-blue">{{ service.summary }}</p>
      <button
        v-if="getProfile.roles.includes('admin')"
        class="btn btn-outline-success"
        @click.prevent="editService"
      >
        EDIT
      </button>
      <button
        v-if="getProfile.roles.includes('admin')"
        class="btn btn-outline-danger"
        @click.prevent="deleteService"
      >
        DELETE
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ServiceCard",
  computed: { ...mapGetters(["getProfile"]) },
  data() {
    return {
      error: "",
      editLink: "/services/edit",
    };
  },
  props: {
    service: Object,
  },
  methods: {
    editService() {
      const service = {
        id: this.service.id,
        name: this.service.name,
        summary: this.service.summary,
        details: this.service.details,
      };
      this.$store.commit("serviceStatus", service);
      this.$router.push(this.editLink);
    },
    async deleteService() {
      const res = await this.$store.dispatch("deleteService", this.service.id);
      if (!res.isValid) {
        this.error = "An error has occured";
        return;
      }
    },
    async getService() {
      const service = {
        id: this.service.id,
        name: this.service.name,
        summary: this.service.summary,
        details: this.service.details,
      };
      this.$store.commit("serviceStatus", service);
      this.$router.push(`/services/${this.service.id}`);
    },
  },
};
</script>

<style scoped>
h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.btn {
  margin-right: 10px !important;
}

.word-wrap {
  word-wrap: break-word;
}
</style>
